import { Classroom } from "../classrooms/classroom.model";
import { DBFactory } from "~/classes/database/db_factory";
import { EducationResource } from "../education-resources/education-resource.model";

export type ClassroomTemplates = ClassroomTemplate[];

export class ClassroomTemplate extends Classroom {
  get databaseConfig() {
    return {
      path: `classroomTemplates/${this.id}`,
      collection: "classroomTemplates",
    };
  }

  static fromMap(data: any): ClassroomTemplate {
    if (data["resources"]) {
      data["resources"] = data["resources"].map((resource: any) => {
        return EducationResource.fromMap(resource);
      });
    }

    return new ClassroomTemplate(data);
  }

  static fromClassroom(classroom: Classroom): ClassroomTemplate {
    const map = classroom.toMap() as any;
    delete map.id;
    map.numGradedDocuments = 0;
    return new ClassroomTemplate(map);
  }

  toClassroom(): Classroom {
    const db = DBFactory.createDatabase();

    const map = this.toMap() as any;
    map.id = db.generateId({
      collection: "classrooms",
      path: "classrooms",
    });
    return new Classroom(map);
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }
}
