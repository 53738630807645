import { AssignmentResource } from "../assignments/assignment-resource.model";
import { DBFactory } from "~/classes/database/db_factory";

export type AssignmentResourceTemplates = AssignmentResourceTemplate[];

export class AssignmentResourceTemplate extends AssignmentResource {
  get databaseConfig() {
    return {
      collection: `/classroomTemplates/${this.classroomId}/assignments/${this.assignmentId}/resources`,
      path: `/classroomTemplates/${this.classroomId}/assignments/${this.assignmentId}/resources/${this.id}`,
    };
  }

  static fromMap(map: any): AssignmentResourceTemplate {
    return new AssignmentResourceTemplate(map);
  }

  static fromAssignmentResource({
    assignmentResource,
    classroomTemplateId,
    assignmentTemplateId,
  }: {
    assignmentResource: AssignmentResource;
    classroomTemplateId: string;
    assignmentTemplateId: string;
  }): AssignmentResourceTemplate {
    const map = assignmentResource.toMap() as any;
    delete map.id;
    map.classroomId = classroomTemplateId;
    map.assignmentId = assignmentTemplateId;

    return new AssignmentResourceTemplate(map);
  }

  toAssignmentResource({
    classroomId,
    assignmentId,
  }: {
    classroomId: string;
    assignmentId: string;
  }): AssignmentResource {
    const map = this.toMap() as any;
    delete map.id;
    map.classroomId = classroomId;
    map.assignmentId = assignmentId;

    return new AssignmentResource(map);
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }
}
