import { ClassSessionPrivateNotes } from "../class-sessions/class-session-private-notes.model";
import { ClassSession } from "../class-sessions/class-session.model";
import { DBFactory } from "~/classes/database/db_factory";
import { EducationResource } from "../education-resources/education-resource.model";

export type ClasssessionTemplates = ClassSessionTemplate[];

export class ClassSessionTemplate extends ClassSession {
  displayOrder: number;

  constructor(data: any) {
    super(data);

    this.displayOrder = data.displayOrder;
  }

  get databaseConfig() {
    return {
      collection: `/classroomTemplates/${this.classroomId}/classSessions`,
      path: `/classroomTemplates/${this.classroomId}/classSessions/${this.id}`,
    };
  }

  static fromMap(data: any): ClassSessionTemplate {
    if (data["resources"]) {
      data["resources"] = data["resources"].map((resource: any) => {
        return EducationResource.fromMap(resource);
      });
    }

    if (data["privateNotes"]) {
      data["privateNotes"] = ClassSessionPrivateNotes.fromMap(
        data["privateNotes"]
      );
    }

    return new ClassSessionTemplate(data);
  }

  toMap(): ModelDatabaseData {
    return {
      ...super.toMap(),
      displayOrder: this.displayOrder,
      privateNotes: this.privateNotes?.toMap(),
    };
  }

  static fromClassSession({
    classSession,
    classroomTemplateId,
    displayOrder,
  }: {
    classSession: ClassSession;
    classroomTemplateId: string;
    displayOrder: number;
  }): ClassSessionTemplate {
    const map = classSession.toMap() as any;
    delete map.id;
    map.classroomId = classroomTemplateId;
    map.displayOrder = displayOrder;
    map.privateNotes = classSession.privateNotes?.toMap();
    return ClassSessionTemplate.fromMap(map);
  }

  toClassSession({ classroomId }: { classroomId: string }): ClassSession {
    const db = DBFactory.createDatabase();

    const map = this.toMap() as any;
    map.id = db.generateId({
      collection: `/classrooms/${classroomId}/classSessions`,
      path: `/classrooms/${classroomId}/classSessions`,
    });
    map.classroomId = classroomId;
    map.meetingUrl = undefined;
    map.scheduledAtTimestamp = undefined;

    if (map.privateNotes) {
      map.privateNotes.classSessionId = map.id;
    }

    return ClassSession.fromMap(map);
  }
  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }
}
