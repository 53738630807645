import { Assignment } from "../assignments/assignment.model";
import { DBFactory } from "~/classes/database/db_factory";

export type AssignmentTemplates = AssignmentTemplate[];

export class AssignmentTemplate extends Assignment {
  get databaseConfig() {
    return {
      collection: `/classroomTemplates/${this.classroomId}/assignments`,
      path: `/classroomTemplates/${this.classroomId}/assignments/${this.id}`,
    };
  }

  static fromMap(data: any): AssignmentTemplate {
    return new AssignmentTemplate(data);
  }

  static fromAssignment({
    assignment,
    classroomTemplateId,
  }: {
    assignment: Assignment;
    classroomTemplateId: string;
  }): AssignmentTemplate {
    const db = DBFactory.createDatabase();

    const map = assignment.toMap() as any;
    map.id = db.generateId({
      path: `/classroomTemplates/${classroomTemplateId}/assignments`,
      collection: `/classroomTemplates/${classroomTemplateId}/assignments`,
    });
    map.classroomId = classroomTemplateId;
    map.numGradedDocuments = 0;
    map.numGradedFinalDraftDocuments = 0;

    return new AssignmentTemplate(map);
  }

  toAssignment(classroomId: string): Assignment {
    const db = DBFactory.createDatabase();
    const map = this.toMap() as any;
    map.id = db.generateId({
      path: `/classrooms/${classroomId}/assignments`,
      collection: `/classrooms/${classroomId}/assignments`,
    });
    map.classroomId = classroomId;
    map.openTimestamp = undefined;
    map.closeTimestamp = undefined;
    map.dueTimestamp = undefined;

    return new Assignment(map);
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }
}
