import { DBFactory } from "~/classes/database/db_factory";
import {
  ClassroomTemplate,
  ClassroomTemplates,
} from "./classroom-template.model";
import { AssignmentTemplate } from "./assignment-template.model";
import { ClassSessionTemplate } from "./class-session-template.model";
import {
  AssignmentResourceTemplate,
  AssignmentResourceTemplates,
} from "./assignment-resource-template.model";
import { Classroom } from "~/classes/models/classrooms/classroom.model";
import { AssignmentsService } from "../assignments/assignments.service";
import { ClassSessionsService } from "../class-sessions/class-sessions.service";
import { Observable, map } from "rxjs";

export class ClassroomTemplatesService {
  static streamUserClassroomTemplates({
    userId,
  }: {
    userId: string;
  }): Observable<ClassroomTemplates> {
    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          collection: `/classroomTemplates`,
          path: `/classroomTemplates`,
        },
        [
          {
            type: "where",
            field: "userId",
            operator: "==",
            value: userId,
          },
        ]
      )
      .pipe(
        map((data) => {
          return data.map((classroomTemplate: any) => {
            return ClassroomTemplate.fromMap(classroomTemplate);
          });
        })
      );
  }

  static async fetchUserClassroomTemplates({ userId }: { userId: string }) {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        collection: `/classroomTemplates`,
        path: `/classroomTemplates`,
      },
      [
        {
          type: "where",
          field: "userId",
          value: userId,
          operator: "==",
        },
      ]
    );

    return data.map((classroomTemplate: any) => {
      return ClassroomTemplate.fromMap(classroomTemplate);
    });
  }

  static async fetchClassroomTemplate({
    classroomTemplateId,
  }: {
    classroomTemplateId: string;
  }) {
    const db = DBFactory.createDatabase();
    const classroomTemplate = await db.get({
      collection: `/classroomTemplates`,
      path: `/classroomTemplates/${classroomTemplateId}`,
    });

    return ClassroomTemplate.fromMap(classroomTemplate) as ClassroomTemplate;
  }

  static async fetchClassroomTemplateAssignments({
    userId,
    classroomTemplateId,
  }: {
    userId: string;
    classroomTemplateId: string;
  }) {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        collection: `/classroomTemplates/${classroomTemplateId}/assignments`,
        path: `/classroomTemplates/${classroomTemplateId}/assignments`,
      },
      [
        {
          type: "where",
          field: "userId",
          value: userId,
          operator: "==",
        },
      ]
    );

    return data.map((assignmentTemplate: any) => {
      return AssignmentTemplate.fromMap(assignmentTemplate);
    });
  }

  static async fetchClassroomTemplateAssignmentResources({
    userId,
    classroomTemplateId,
  }: {
    userId: string;
    classroomTemplateId: string;
  }) {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        collectionGroup: "resources",
        collection: "resources",
        path: "resources",
      },
      [
        {
          type: "where",
          field: "classroomId",
          value: classroomTemplateId,
          operator: "==",
        },
        {
          type: "where",
          field: "userId",
          value: userId,
          operator: "==",
        },
      ]
    );

    return data.map((assignmentResourceTemplate: any) => {
      return AssignmentResourceTemplate.fromMap(assignmentResourceTemplate);
    });
  }

  static async fetchAssignmentTemplateResources({
    userId,
    classroomTemplateId,
    assignmentTemplateId,
  }: {
    userId: string;
    classroomTemplateId: string;
    assignmentTemplateId: string;
  }) {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        collection: `/classroomTemplates/${classroomTemplateId}/assignments/${assignmentTemplateId}/resources`,
        path: `/classroomTemplates/${classroomTemplateId}/assignments/${assignmentTemplateId}/resources`,
      },
      [
        {
          type: "where",
          field: "userId",
          value: userId,
          operator: "==",
        },
      ]
    );

    return data.map((assignmentResourceTemplate: any) => {
      return AssignmentResourceTemplate.fromMap(assignmentResourceTemplate);
    });
  }

  static async fetchClassroomClassSessionTemplates({
    userId,
    classroomTemplateId,
  }: {
    userId: string;
    classroomTemplateId: string;
  }) {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        collection: `/classroomTemplates/${classroomTemplateId}/classSessions`,
        path: `/classroomTemplates/${classroomTemplateId}/classSessions`,
      },
      [
        {
          type: "where",
          field: "userId",
          value: userId,
          operator: "==",
        },
      ]
    );

    return data.map((classSessionTemplate: any) => {
      return ClassSessionTemplate.fromMap(classSessionTemplate);
    });
  }

  static async saveClassroomAsTemplate({
    classroom,
  }: {
    classroom: Classroom;
  }) {
    const classroomTemplate = ClassroomTemplate.fromClassroom(classroom);
    await classroomTemplate.save();

    if (!classroom.id || !classroomTemplate.id) {
      throw new Error("Classroom or Classroom template Id is missing");
    }

    const assignments = await AssignmentsService.listAssignments(
      classroom.id!,
      classroom.userId
    );

    const activeAssignments = assignments
      .filter((assignment) => !assignment.isArchived)
      .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));

    const mapAssignmentIdToTemplateId: { [key: string]: string } = {};

    const assignmentTemplates = activeAssignments.map((assignment, index) => {
      assignment.displayOrder = index;

      const assignmentTemplate = AssignmentTemplate.fromAssignment({
        assignment,
        classroomTemplateId: classroomTemplate.id!,
      });

      mapAssignmentIdToTemplateId[assignment.id!] = assignmentTemplate.id!;

      return assignmentTemplate;
    });

    const classSessions =
      await ClassSessionsService.fetchClassroomClassSessions({
        userId: classroom.userId,
        classroomId: classroom.id!,
      });

    const activeClassSessions = classSessions.filter(
      (classSession) => classSession.isArchived != true
    );

    const classSerssionTempaltes = activeClassSessions.map(
      (classSession, index) => {
        return ClassSessionTemplate.fromClassSession({
          classSession,
          classroomTemplateId: classroomTemplate.id!,
          displayOrder: index,
        });
      }
    );

    const assignmentResources =
      await AssignmentsService.fetchAllClassroomAssignmentResources({
        userId: classroom.userId,
        classroomId: classroom.id!,
      });

    const assignmentResourceTemplates = [] as AssignmentResourceTemplates;
    for (const assignmentResource of assignmentResources) {
      if (
        mapAssignmentIdToTemplateId[assignmentResource.assignmentId] ==
        undefined
      )
        continue;

      const assignmentResourceTemplate =
        AssignmentResourceTemplate.fromAssignmentResource({
          assignmentResource,
          classroomTemplateId: classroomTemplate.id!,
          assignmentTemplateId:
            mapAssignmentIdToTemplateId[assignmentResource.assignmentId],
        });

      assignmentResourceTemplates.push(assignmentResourceTemplate);
    }

    const db = DBFactory.createDatabase();
    await db.batchUpdate([
      ...assignmentTemplates,
      ...classSerssionTempaltes,
      ...assignmentResourceTemplates,
    ]);

    for (const group of classroomTemplate.classroomAssignmentGroups ?? []) {
      group.assignmentIds = group.assignmentIds.map((assignmentId) => {
        return mapAssignmentIdToTemplateId[assignmentId];
      });
    }

    await classroomTemplate.save();

    await ClassroomTemplatesService.copyClassroomTemplateResources({
      classroomTemplateId: classroomTemplate.id!,
    });
  }

  static async copyClassroomTemplateResources({
    classroomTemplateId,
  }: {
    classroomTemplateId: string;
  }) {
    if (process.server) return;

    await $fetch(
      `/api/lms/classrooms/template/${classroomTemplateId}/copy-resources`,
      {
        method: "GET",
        headers: await useApiHeaders(),
      }
    );
  }

  static async createClassroomFromTemplate({
    classroomTemplate,
  }: {
    classroomTemplate: ClassroomTemplate;
  }) {
    const classroom = classroomTemplate.toClassroom();

    const assignmentTemplateIdsToAssignmentIds: { [key: string]: string } = {};

    const assignmentTemplates =
      await ClassroomTemplatesService.fetchClassroomTemplateAssignments({
        userId: classroom.userId,
        classroomTemplateId: classroomTemplate.id!,
      });

    assignmentTemplates.sort(
      (a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0)
    );

    const classSessionTemplates =
      await ClassroomTemplatesService.fetchClassroomClassSessionTemplates({
        userId: classroom.userId,
        classroomTemplateId: classroomTemplate.id!,
      });

    classSessionTemplates.sort(
      (a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0)
    );

    const assignments = assignmentTemplates.map((assignmentTemplate) => {
      const assignment = assignmentTemplate.toAssignment(classroom.id!);
      assignmentTemplateIdsToAssignmentIds[assignmentTemplate.id!] =
        assignment.id!;
      return assignment;
    });

    const classSessions = classSessionTemplates.map((classSessionTemplate) => {
      return classSessionTemplate.toClassSession({
        classroomId: classroom.id!,
      });
    });

    const assignmentResourceTemplates =
      await ClassroomTemplatesService.fetchClassroomTemplateAssignmentResources(
        {
          userId: classroom.userId,
          classroomTemplateId: classroomTemplate.id!,
        }
      );

    const assignmentResources = assignmentResourceTemplates.map(
      (assignmentResourceTemplate) => {
        return assignmentResourceTemplate.toAssignmentResource({
          classroomId: classroom.id!,
          assignmentId:
            assignmentTemplateIdsToAssignmentIds[
              assignmentResourceTemplate.assignmentId
            ],
        });
      }
    );

    return {
      classroom,
      assignments,
      assignmentResources,
      classSessions,
    };
  }
}
